<template>
  <div>
    <template class="mt-0">
      <advertisement-top-banner-text />
    </template>
    <template v-if="$options.components['advertisement-loading']">
      <advertisement-loading v-if="isLoading" />
    </template>
    <div else>
      <sections-slider
        v-if="$route.params.items == `Most Popular`"
        :list="itemSuggestions"
        :subTitle="$route.params.items"
        :showTitle="true"
        :hasBackground="true"
        :hasButton="false"
        :withoutSlider="true"
      >
        <template slot="default" slot-scope="{ listItem }">
          <div class="item-box">
            <items-item :itemNoBg="true" :product="listItem" :incrementDecrementKey="true"></items-item>
          </div>
        </template>
      </sections-slider>

      <sections-slider
        v-if="$route.params.items == `Daily Essentials`"
        :list="newArrivals"
        :subTitle="$route.params.items"
        :showTitle="true"
        :hasBackground="true"
        :hasButton="false"
        :withoutSlider="true"
      >
        <template slot="default" slot-scope="{ listItem }">
          <div class="item-box">
            <items-item :itemNoBg="true" :product="listItem" :incrementDecrementKey="true"></items-item>
          </div>
        </template>
      </sections-slider>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      isLoading: false,
    };
  },
  created() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
  computed: {
    ...mapState({
      itemSuggestions: (state) => state.healthCare.itemSuggestions,
      newArrivals: (state) => state.healthCare.newArrivals,
    }),
  },
  async mounted() {
    this.isLoading = true;
    await this.$store.dispatch(
      "healthCare/fetchAllItems",
      this.$route.params.market
    );
    this.isLoading = false;
  },
};
</script>

