var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [[_c('advertisement-top-banner-text')], _vm.$options.components['advertisement-loading'] ? [_vm.isLoading ? _c('advertisement-loading') : _vm._e()] : _vm._e(), _c('div', {
    attrs: {
      "else": ""
    }
  }, [_vm.$route.params.items == "Most Popular" ? _c('sections-slider', {
    attrs: {
      "list": _vm.itemSuggestions,
      "subTitle": _vm.$route.params.items,
      "showTitle": true,
      "hasBackground": true,
      "hasButton": false,
      "withoutSlider": true
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var listItem = _ref.listItem;
        return [_c('div', {
          staticClass: "item-box"
        }, [_c('items-item', {
          attrs: {
            "itemNoBg": true,
            "product": listItem,
            "incrementDecrementKey": true
          }
        })], 1)];
      }
    }], null, false, 1522884298)
  }) : _vm._e(), _vm.$route.params.items == "Daily Essentials" ? _c('sections-slider', {
    attrs: {
      "list": _vm.newArrivals,
      "subTitle": _vm.$route.params.items,
      "showTitle": true,
      "hasBackground": true,
      "hasButton": false,
      "withoutSlider": true
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var listItem = _ref2.listItem;
        return [_c('div', {
          staticClass: "item-box"
        }, [_c('items-item', {
          attrs: {
            "itemNoBg": true,
            "product": listItem,
            "incrementDecrementKey": true
          }
        })], 1)];
      }
    }], null, false, 1522884298)
  }) : _vm._e()], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }